import { DocumentType, ProduitType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import Item from './Item';

interface SlotsProps {
  datas?: DocumentType<ProduitType>[];
}

const Slots: FC<SlotsProps> = ({ datas }) => {
  if (!datas || 0 === datas.length) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2">
      {datas
        .sort((a, b) =>
          (a.variantValues?.slot?.startDate || 'Z').localeCompare(
            b.variantValues?.slot?.startDate || '0',
          ),
        )
        .map(data => (
          <Item key={data.id} data={data} />
        ))}
    </div>
  );
};

export default Slots;
