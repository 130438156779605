import { DocumentType, ProduitType } from '@innedit/innedit-type';
import dayjs, { Dayjs } from 'dayjs';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Button from '~/components/Button';
import Calendar from '~/components/Calendar';
import { calcEnd } from '~/components/Slot';
import Slots from '~/components/Slots';

const ProductWithSlots: FC<{
  produit: DocumentType<ProduitType>;
}> = ({ produit }) => {
  const [selected, setSelected] = useState<Dayjs>();
  const [slots, setSlots] = useState<DocumentType<ProduitType>[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const slotData = new ProduitData({
      espaceId: produit.espaceId,
      wheres: {
        parent: produit.id,
      },
    });

    slotData
      .find()
      .then(setSlots)
      .catch(e => setError(e.message));
  }, [produit.id]);

  const handleOnSelect = (date: string) => {
    const day = dayjs(date);
    setSelected(day);
  };

  if (error) {
    return <div>{error}</div>;
  }

  const selectedSlots =
    selected &&
    slots?.filter(
      ({ variantValues }) =>
        variantValues && selected.isSame(dayjs(variantValues.slot?.startDate)),
    );

  return (
    <div className="flex justify-between space-x-12">
      {slots ? (
        <div>
          <Slots datas={slots} />
        </div>
      ) : (
        <div>Chargement en cours des créneaux</div>
      )}
      <div>
        <Calendar onSelect={handleOnSelect} selected={selected} slots={slots} />
        {selected && (
          <div>
            Créneaux du {selected.format('dddd D MMM YYYY')} :
            {selectedSlots &&
              selectedSlots.length > 0 &&
              selectedSlots.map(({ id, variantValues }) => (
                <div key={id}>
                  {variantValues?.slot?.startTime &&
                    `[${variantValues.slot.startTime}-${calcEnd(
                      variantValues.slot.startTime,
                      variantValues.slot.duration,
                    )}]`}
                  <Button
                    to={`/${produit.espaceId}/${
                      produit.id
                    }/${id}/${selected.format('YYYY-MM-DD')}/`}
                    variant="link"
                  >
                    Réserver
                  </Button>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductWithSlots;
