import { DocumentType, ProduitType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { ElementType, FC } from 'react';
import { useTranslation } from 'react-i18next';

dayjs.locale('fr');

export const calcEnd = (
  startTime: string,
  duration?: string,
): string | null => {
  if (!duration) {
    return null;
  }

  const [startH, startM] = startTime.split(':');
  const [durationH, durationM] = duration.split(':');

  return `${Number(startH) + Number(durationH)}:${String(
    Number(startM) + Number(durationM),
  ).padStart(2, '0')}`;
};

interface SlotProps {
  as?: ElementType;
  data: DocumentType<ProduitType>;
  showDate?: boolean;
  showPlaces?: boolean;
}

const Slot: FC<SlotProps> = ({
  as,
  data,
  showDate = true,
  showPlaces = true,
}) => {
  const { t } = useTranslation();

  const { variantValues } = data;
  if (!variantValues) {
    return <div>Ce n&apos;est pas une déclinaison</div>;
  }
  const { slot } = variantValues;
  if (!slot) {
    return <div>Ce n&apos;est pas une déclinaison avec un créneau</div>;
  }
  const { startDate, startTime, duration } = slot;

  if (!showDate && !showPlaces) {
    return React.createElement(
      as ?? React.Fragment,
      {},
      compact([
        startTime,
        startTime ? calcEnd(startTime, duration) : undefined,
      ]).join(' - '),
    );
  }

  const info = (
    <div className="flex justify-between">
      <span>
        {compact([
          startTime,
          startTime ? calcEnd(startTime, duration) : undefined,
        ]).join(' - ')}
      </span>
      {showPlaces && (
        <span className="text-light-800 italic">{t('slot.places')}</span>
      )}
    </div>
  );

  if (!showDate) {
    return React.createElement(as ?? React.Fragment, {}, info);
  }

  return React.createElement(
    as ?? React.Fragment,
    {},
    <div className="flex flex-col w-full">
      <span>{dayjs(`${startDate}`).format('dddd D MMM YYYY')}</span>
      {info}
    </div>,
  );
};

export default Slot;
