import { DocumentType, ProduitType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import times from 'lodash/times';
import React, { FC, PropsWithChildren, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrowLeft from '../../icons/ArrowLeft';
import IconArrowRight from '../../icons/ArrowRight';
import Button from '../Button';

dayjs.locale('fr');
dayjs.extend(weekday);

const Week: FC<
  PropsWithChildren<{
    currentMonth: number;
    onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
    selected?: Dayjs;
    slots?: DocumentType<ProduitType>[];
    start: Dayjs;
  }>
> = ({ currentMonth, onClick, selected, slots, start }) => (
  <>
    {times(7).map(i => {
      const day = start.add(i, 'day');

      const hasAtLeastOneSlot =
        slots &&
        slots.some(s => day.isSame(dayjs(s.variantValues?.slot?.startDate)));

      const isSelectedDay = selected && day.isSame(selected);

      return (
        <div
          key={i}
          className={classnames('flex items-center justify-center p-2', {
            'text-light-700': day.month() !== currentMonth,
          })}
        >
          {hasAtLeastOneSlot ? (
            <Button
              color={isSelectedDay ? 'primary' : 'neutral'}
              data-date={day.toISOString()}
              onClick={onClick}
              variant="calendar-day"
            >
              {day.format('D')}
            </Button>
          ) : (
            <span> {day.format('D')}</span>
          )}
        </div>
      );
    })}
  </>
);

const Calendar: FC<{
  onSelect: (date: string) => void;
  selected?: Dayjs;
  slots?: DocumentType<ProduitType>[];
}> = ({ onSelect, selected, slots }) => {
  const { t } = useTranslation();
  const [day, setDay] = useState<Dayjs>(dayjs());

  const handlePrevMonthOnClick = () => {
    setDay(oldDay => oldDay.subtract(1, 'month'));
  };

  const handleNextMonthOnClick = () => {
    setDay(oldDay => oldDay.add(1, 'month'));
  };

  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const date = event.currentTarget.getAttribute('data-date');
    if (date) {
      onSelect(date);
    }
  };

  const firstOfMonth = day.startOf('month');
  const firstOfWeek = firstOfMonth.startOf('week');
  const nbDaysInMonth = day.daysInMonth();
  const nbWeeksInMonth = Math.ceil(
    (nbDaysInMonth + firstOfMonth.weekday()) / 7,
  );

  const array = times(nbWeeksInMonth);

  return (
    <>
      <div className="mb-6 flex items-center justify-between">
        <span className="text-md">{day.format('MMMM YYYY')}</span>
        <div>
          <Button
            iconLeft={IconArrowLeft}
            onClick={handlePrevMonthOnClick}
            variant="link"
          />
          <Button
            iconLeft={IconArrowRight}
            onClick={handleNextMonthOnClick}
            variant="link"
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div className="grid grid-cols-7">
          {times(7).map(i => (
            <div
              key={i}
              className="mb-3 flex items-center justify-center text-xs uppercase"
            >
              {t(`days.${i}`).slice(0, 3)}.
            </div>
          ))}
          {array.map(i => (
            <Week
              key={i}
              currentMonth={day.month()}
              onClick={handleOnClick}
              selected={selected}
              slots={slots}
              start={firstOfWeek.add(i, 'week')}
            >
              {i}
            </Week>
          ))}
        </div>
      </div>
    </>
  );
};

export default Calendar;
