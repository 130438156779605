import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Media from '~/components/Media';
import ProductWithSlots from '~/containers/Payment/ProductWithSlots';
import { UserProps } from '~/utils/requireUser';

const PageProduct: FC<PageProps & Partial<UserProps>> = ({
  params: { productId },
}) => {
  const [produit, setProduit] = useState<DocumentType<ProduitType>>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const produitData = new GenericData<ProduitType>({
      collectionName: 'produits',
    });
    produitData
      .findById(productId)
      .then(setProduit)
      .catch(e => setError(e.message));
  }, [productId]);

  if (!produit) {
    return null;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!produit) {
    return <div>Chargement en cours</div>;
  }

  const { description, label, hasOptions, hasSlots, medias, options } = produit;

  return (
    <div className="m-3 flex flex-col space-y-3">
      <h1 className="text-center text-xl">{label}</h1>
      {description && <p className="text-center">{description}</p>}
      {hasOptions && options && options.length > 0 && (
        <div>
          <h2>Options</h2>
          <div>
            {options.map(option => (
              <div key={option.label}>
                <h3>{option.label}</h3>
                <ul className="flex space-x-3">
                  {option.values.map(value => (
                    <li key={value}>{value}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
      {medias && medias.length > 0 && (
        <div className="grid grid-cols-2 gap-3 md:grid-cols-4">
          {medias.map(media => (
            <Media key={media.id} media={media} />
          ))}
        </div>
      )}
      {hasSlots && <ProductWithSlots produit={produit} />}
    </div>
  );
};

export default PageProduct;
